import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchMetaData = () => {
  const [metaData, setMetaData] = useState([]);
  const [url, setUrl] = useState();
  const [getMetaIsActive, setGetMetaActive] = useState(false);

  async function fetchData() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const result = await axios({
        method: 'POST',
        baseURL: 'https://service.radiomii.com/?',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: new URLSearchParams({ url }).toString(),
        cancelToken: source.token,
        timeout: 50000,
      });

      setMetaData(result.data);
    } catch (error) {
      setGetMetaActive(false);
      setMetaData([]);
    }
  }

  useEffect(() => {
    let timeout = null;

    if (getMetaIsActive && url) {
      timeout = setTimeout(() => {
        fetchData();
      }, 5000);
    } else {
      metaData.length ?? setMetaData([]);
    }

    return () => clearTimeout(timeout);
  }, [metaData, url, getMetaIsActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return { metaData, setGetMetaActive, setUrl };
};

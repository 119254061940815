import { useEffect } from 'react';
import { useAppStore, useSettingsStore } from './store';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyle } from './GlobalStyle';
import Main from './components/Main';
import Dialogs from './components/Dialogs/Dialogs';
import { isDevelop, isSafari, isTouch } from './utils/system';

export default function App() {
  const {
    system,
    setSystem,
    setStationId,
    VIEW,
    setViewMode,
    error,
    setCountries,
  } = useAppStore((state) => ({
    system: state.system,
    setSystem: state.setSystem,
    setStationId: state.setStationId,
    VIEW: state.VIEW,
    setViewMode: state.setViewMode,
    error: state.error,
    setCountries: state.setCountries,
  }));

  const { light, setModeList, initialStart, setInitialStart, setShowTooltips } =
    useSettingsStore((state) => ({
      light: state.light,
      setModeList: state.setModeList,
      initialStart: state.initialStart,
      setInitialStart: state.setInitialStart,
      setShowTooltips: state.setShowTooltips,
    }));

  /* eslint-disable */
  useEffect(() => {
    setSystem({
      isTouch: isTouch(),
      isDevelop: isDevelop(),
      isSafari: isSafari(),
    });

    setCountries();
  }, []);

  useEffect(() => {
    if (system.isTouch) {
      setShowTooltips(false);

      if (initialStart) {
        setModeList(true);
        setInitialStart(false);
      }
    }
  }, [system.isTouch]);

  useEffect(() => {
    // search for URI stationUUID param like:
    // .../?id=963ccae5-0601-11e8-ae97-52543be04c81

    const query = new URLSearchParams(window.location.search);
    const uuid = query.get('id');

    if (uuid) {
      setStationId(uuid);

      const url = new URL(window.location);
      url.searchParams.delete('id');
      window.history.replaceState(null, null, url);
    }
  }, []);

  useEffect(() => {
    if (error.set) {
      setViewMode(VIEW.EMPTY_STATE_ERROR);
    }
  }, [error]);
  /* eslint-enable */

  return (
    <div className='App'>
      <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <GlobalStyle />
        <Main />
        <Dialogs />
      </ThemeProvider>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useAppStore } from '../store';
import { getItemFromStorage, storeItem } from '../utils/localStorage';

export function useStoreFavorites(storeFavorite) {
  const [key, setKey] = useState('');

  const VIEW = useAppStore((state) => state.VIEW);
  const viewMode = useAppStore((state) => state.viewMode);
  const forceRender = useAppStore((state) => state.forceRender);
  const setStoreFavorite = useAppStore((state) => state.setStoreFavorite);

  useEffect(() => {
    const initialState = {
      results: [],
    };

    let array = getItemFromStorage('favorites');

    if (!array) {
      array = initialState;
    }

    // change favorites order
    if (storeFavorite.move) {
      let position = 0;

      array &&
        array.results.map((favorite, idx) => {
          if (favorite.stationuuid === storeFavorite.station.stationuuid) {
            position = idx;
          }

          return 1;
        });

      array.results.splice(position, 1);

      switch (key) {
        case 'ArrowUp':
          array.results.splice(0, 0, storeFavorite.station);
          break;
        case 'ArrowLeft':
          if (position >= 1) {
            array.results.splice(position - 1, 0, storeFavorite.station);
          } else {
            array.results.splice(position, 0, storeFavorite.station);
          }
          break;
        case 'ArrowRight':
          array.results.splice(position + 1, 0, storeFavorite.station);
          break;
        case 'ArrowDown':
          array.results.push(storeFavorite.station);
          break;
        default:
          array.results.splice(position, 0, storeFavorite.station);
      }

      setKey('');
    } else {
      if (storeFavorite.isFavorite !== undefined) {
        // remove favorite from local storage
        array &&
          array.results.map((favorite, idx) => {
            if (
              !storeFavorite.isFavorite &&
              favorite.stationuuid === storeFavorite.station.stationuuid
            ) {
              array.results.splice(idx, 1);
            }

            return 1;
          });

        // add favorite and avoid storing duplicates
        if (
          storeFavorite.isFavorite &&
          !array.results.some((e) => e.id === storeFavorite.station.stationuuid)
        ) {
          array.results.push(storeFavorite.station);
        }

        setStoreFavorite({
          isFavorite: undefined,
        });
      }
    }

    storeItem('favorites', array);

    if ((!storeFavorite.isFavorite && viewMode !== VIEW.RESULTS) || key) {
      forceRender();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFavorite, key]);

  return { setKey };
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppStore, useSettingsStore } from '../../store';
import { getItemFromStorage } from '../../utils/localStorage';
import { removeMarkdown, getPlainName } from '../../utils/string';
import { isHttps } from '../../utils/system';
import Flags from 'country-flag-icons/react/3x2';
import RadioSvg from '../../images/radio.svg';
import RadioPng from '../../images/radio.png';
import {
  IoThumbsUpSharp,
  IoPlaySharp,
  IoLockClosedSharp,
} from 'react-icons/io5';
import { StationMenu, ToggleHeart, Tooltip } from '../';
import { sendStationClick } from '../../RadioBrowserApi';

import {
  Wrapper,
  Content,
  IconStation,
  IconPlay,
  NameStation,
  Footer,
  Label,
  HeartWrap,
  MenuWrap,
} from './Card.styles';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

export default function Card({ ...props }) {
  const [showPlayer, setShowPlayer] = useState(false);
  const [showDots, setShowDots] = useState(false);
  const [showImg, setShowImg] = useState(true);
  const [wiggleCard, setWiggleCard] = useState(false);

  const compactCard = useSettingsStore((state) => state.compactCard);

  const {
    setActiveStation,
    VIEW,
    viewMode,
    storeFavorite,
    setStoreFavorite,
    system,
  } = useAppStore((state) => ({
    setActiveStation: state.setActiveStation,
    VIEW: state.VIEW,
    viewMode: state.viewMode,
    storeFavorite: state.storeFavorite,
    setStoreFavorite: state.setStoreFavorite,
    system: state.system,
  }));

  if (!storeFavorite.move && wiggleCard) {
    setWiggleCard(false);
  }

  var stationMenuVisible =
    viewMode === VIEW.FAVORITES && !compactCard && !storeFavorite.move;

  const Flag = Flags[props.station.countrycode];

  const tags = props.station.tags
    .toString()
    .split(',')
    .filter((tag) => tag.length > 0 && tag.length < 16);

  const name = removeMarkdown(props.station.name);

  function startAudio(url) {
    props.player.current.audio.current.src = url;

    sendStationClick(props.station.stationuuid);

    setActiveStation({
      name: name,
      url: url,
      uuid: props.station.stationuuid,
      image: showImg ? props.station.favicon : RadioPng,
    });
  }

  function getEnabledItems() {
    var hasHomepage = 0;
    var hasMoreFavorites = 0;

    let array = getItemFromStorage('favorites');

    props.station.homepage ? (hasHomepage = 4) : (hasHomepage = 0);

    if (array) {
      if (array.results.length > 1) {
        hasMoreFavorites = 2;
      }
    }

    return hasHomepage + hasMoreFavorites;
  }

  return (
    <Wrapper
      $wiggle={wiggleCard}
      $compactCard={compactCard}
      $move={storeFavorite.move}
      onMouseEnter={() => {
        !system.isTouch && setShowPlayer(true);
        !system.isTouch && setShowDots(true);
      }}
      onMouseLeave={() => {
        !system.isTouch && setShowPlayer(false);
        !system.isTouch && setShowDots(false);
      }}
      onClick={() => {
        system.isTouch && startAudio(props.station.url_resolved);
      }}
    >
      <Content $compactCard={compactCard}>
        <MenuWrap>
          {stationMenuVisible && (
            <StationMenu
              id={props.id}
              showDots={showDots || system.isTouch}
              setWiggleCard={setWiggleCard}
              enabledItems={getEnabledItems()}
              station={props.station}
            />
          )}
        </MenuWrap>
        <HeartWrap>
          {viewMode !== VIEW.FAVORITES && (
            <ToggleHeart
              checked={props.favorite}
              cbClicked={(checked) => {
                setStoreFavorite({
                  move: false,
                  station: props.station,
                  isFavorite: checked,
                });
              }}
            />
          )}
        </HeartWrap>
        {showImg ? (
          <IconStation
            $compactCard={compactCard}
            src={props.station.favicon}
            alt='station-image'
            onError={() => {
              setShowImg(false);
            }}
          />
        ) : (
          <IconStation src={RadioSvg} alt='station-image' />
        )}
        <NameStation $compactCard={compactCard}>
          {getPlainName(name)}
        </NameStation>
        {!compactCard && (
          <Footer $compactCard={compactCard}>
            {viewMode !== VIEW.FAVORITES && (
              <>
                <Label color='cornflowerblue'>
                  <IoThumbsUpSharp />
                  {'\u00A0'}
                  {formatter.format(props.station.votes)}
                </Label>
                <Label color='#fa7d18'>
                  <IoPlaySharp />
                  {formatter.format(props.station.clickcount)}
                </Label>
              </>
            )}
            {Flag && (
              <Tooltip
                content={props.station.country}
                direction='bottom'
                bgColor='#9e49bd'
                margin='32px'
                delay='0.2s'
              >
                {Flag ? <Flag className='flag' /> : ''}
              </Tooltip>
            )}
            {isHttps(props.station.url) && (
              <Label
                color='mediumseagreen'
                style={{
                  padding: '0.25rem 0.25rem',
                }}
              >
                <IoLockClosedSharp />
              </Label>
            )}
            {props.station.codec && (
              <Label color='darkorchid'>
                {props.station.codec + ' ' + props.station.bitrate}
              </Label>
            )}
            {tags.map((tag, idx) => {
              return <Label key={idx}>{tag}</Label>;
            })}
          </Footer>
        )}
        {showPlayer && !storeFavorite.move && (
          <IconPlay
            onClick={() => {
              startAudio(props.station.url_resolved);
            }}
          >
            <IoPlaySharp />
          </IconPlay>
        )}
      </Content>
    </Wrapper>
  );
}

Card.propTypes = {
  id: PropTypes.number,
  player: PropTypes.object,
  image: PropTypes.string,
  url: PropTypes.string,
  votes: PropTypes.number,
  countryCode: PropTypes.string,
  bitrate: PropTypes.number,
  name: PropTypes.string,
  tags: PropTypes.array,
};

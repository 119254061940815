import { useState } from 'react';
import { useAppStore } from '../../store';

import AudioPlayer from 'react-h5-audio-player';
import { Burger, Menu, Infobox } from '../';
import 'react-h5-audio-player/lib/styles.css';
import './player.css';
import Logo from '../../images/logo.png';
import { Wrapper, Content, LogoImg, InfoBoxWrap } from './Header.styles';
import { useFetchMetaData } from '../../hooks/useFetchMetaData';

export default function Header({ ...props }) {
  const [loadStation, setLoadStation] = useState(false);
  const [showSongInfo, setShowSongInfo] = useState(false);
  const [open, setOpen] = useState(false);

  const activeStation = useAppStore((state) => state.activeStation);
  const setShowDialog = useAppStore((state) => state.setShowDialog);

  const system = useAppStore((state) => state.system);

  const { metaData, setGetMetaActive, setUrl } = useFetchMetaData();

  return (
    <Wrapper>
      <Content>
        <Burger className='burger' open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} id={'main-menu'} />
        <LogoImg
          className='burger'
          src={Logo}
          alt='yorda-logo'
          onClick={() => {
            setShowDialog({ info: true });
          }}
        />
        <InfoBoxWrap className='info'>
          <Infobox
            metaData={metaData}
            loadStation={loadStation}
            showSongInfo={showSongInfo}
            {...props}
          />
        </InfoBoxWrap>
        <AudioPlayer
          className='controls'
          style={system.isTouch ? { width: 72 } : {}}
          volume={system.isTouch ? '1.0' : '0.7'}
          autoPlay={true}
          showJumpControls={false}
          showFilledVolume={true}
          layout='stacked'
          customProgressBarSection={[]}
          customControlsSection={
            system.isTouch
              ? ['MAIN_CONTROLS']
              : ['MAIN_CONTROLS', 'VOLUME_CONTROLS']
          }
          autoPlayAfterSrcChange={false}
          hasDefaultKeyBindings={false}
          onLoadStart={(e) => {
            setLoadStation(true);
            setShowSongInfo(false);
            setGetMetaActive(false);
            setUrl(activeStation.url);
          }}
          onPlaying={(e) => {
            setLoadStation(false);
            setShowSongInfo(true);
            setGetMetaActive(true);
          }}
          onPause={(e) => {
            setShowSongInfo(false);
            setGetMetaActive(false);
          }}
          onError={(e) => console.log('Audioplayer: onError', e)}
          ref={props.player}
        />
      </Content>
    </Wrapper>
  );
}

import styled from 'styled-components';

export const MiiIcon = styled.img`
  width: 96px;
  display: block;
  margin: 12px auto 36px auto;
  animation: wobble 0.5s linear 0.5s 1;

  @keyframes wobble {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

export const KofiIcon = styled.img`
  height: 22px;
  display: block;
  margin: 0 auto 0 6px;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin: 0 4px 4px 6px;
    font-size: 1.3rem;
    color: var(--miiGrey);
    transition: color 0.25s;

    &:hover {
      color: var(--miiOrange);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 40px;
`;

export const Button = styled.button`
  background: ${(props) => (props.$focus ? 'orange' : 'white')};
  color: ${(props) => (props.$focus ? 'white' : 'var(--miiGrey)')};
  border: ${(props) => (props.$focus ? 'none' : '1px solid var(--miiGrey)')};
  border-radius: 4px;
  width: 84px;
  margin-left: 8px;
  padding: ${(props) => (props.$focus ? '6px 0' : '4px 0')};
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    opacity: 0.9;
    border-color: var(--miiGreyMedium);
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--miiGreyDark);
  z-index: 100;
  user-select: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.break.horiz.lg}) {
    flex-wrap: wrap;
    height: auto;
    padding-top: 6px;

    .burger {
      order: 1;
    }

    .logo {
      order: 2;
    }

    .info {
      order: 4;
    }

    .controls {
      order: 3;
    }
  }
`;

export const InfoBoxWrap = styled.div`
  display: block;
  width: 45%;

  @media (max-width: ${({ theme }) => theme.break.horiz.lg}) {
    width: 100%;
    margin: 12px;
    margin-top: 0;
  }
`;

export const LogoImg = styled.img`
  display: block;
  cursor: pointer;
  width: 110px;

  @media (max-width: ${({ theme }) => theme.break.horiz.lg}) {
    margin-left: 36px;
  }
`;

import axios from 'axios';

let radioBrowserUrl = undefined;

const URL_HTTP = 'http://all.api.radio-browser.info/json';
const URL_HTTPS = 'https://de1.api.radio-browser.info/json';

let fixedServer = URL_HTTPS;

if (window.location.protocol === 'http:') {
  fixedServer = URL_HTTP;
}

export let controller;

function handleError(error) {
  if (error.response) {
    console.error('Server Error:', error.response.data);
  } else if (error.request) {
    console.error('Network Error:', error.message);
  } else {
    console.error('Request Error:', error.message);
  }

  throw error;
}

async function fetchBaseUrls() {
  try {
    const result = await axios({
      method: 'GET',
      baseURL: fixedServer,
      url: '/servers',
    });

    const servers = result.data;

    return servers.map((server) => `https://${server.name}/json`);
  } catch (error) {
    handleError(error);
  }
}

async function getBaseUrl() {
  const baseUrls = await fetchBaseUrls();
  return baseUrls[Math.floor(Math.random() * baseUrls.length)];
}

async function createExtRequest(url, queryParams) {
  if (radioBrowserUrl === undefined) {
    radioBrowserUrl = getBaseUrl();
  }

  const controller = new AbortController();

  try {
    const result = await axios({
      method: 'POST',
      baseURL: await radioBrowserUrl,
      url: url,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams(queryParams).toString(),
      signal: controller?.signal,
    });

    return result.data;
  } catch (error) {
    handleError(error);
  }
}

export async function searchStations(queryParams) {
  return createExtRequest('/stations/search', queryParams);
}

export async function getStationsMostClicked(queryParams) {
  return createExtRequest('/stations/topclick', queryParams);
}

export async function getStationsMostVoted(queryParams) {
  return createExtRequest('/stations/topvote', queryParams);
}

export async function getStationsRecentlyClicked(queryParams) {
  return createExtRequest('/stations/lastclick', queryParams);
}

async function createRequest(url) {
  if (radioBrowserUrl === undefined) {
    radioBrowserUrl = getBaseUrl();
  }

  try {
    const result = await axios({
      method: 'GET',
      baseURL: await radioBrowserUrl,
      url: url,
    });

    return result.data;
  } catch (error) {
    handleError(error);
  }
}

export function fetchCountries() {
  return createRequest('/countries');
}

export function fetchLanguages() {
  return createRequest('/languages');
}

export function getStationById(stationId) {
  return createRequest(`stations/byuuid?uuids=${stationId}`);
}

export function sendStationClick(stationId) {
  return createRequest(`/url/${stationId}`);
}

export function voteForStation(stationId) {
  return createRequest(`/vote/${stationId}`);
}

const miiWhite = '#fff';
const miiGreyLight = '#e6e6e6';
const miiGrey = '#868686';
const miiGreyMedium = '#383838';
const miiGreyBrown = '#26211c';

export const lightTheme = {
  color: {
    h1: miiGreyMedium,
    h3: miiGrey,
    p: miiGrey,
    bgBody: miiGreyLight,

    card: {
      bg: miiWhite,
      fg: miiGrey,
      bgHover: '#f0f0f0',
      heartBg: miiGrey,
      labelBg: miiGreyLight,
      LabelFg: miiGreyMedium,
      bgIconPlay: miiGrey,
    },

    dropdown: {
      bg: miiWhite,
      fg: miiGrey,
      itemDisabled: miiGreyLight,
    },

    langsel: {
      bg: miiGreyLight,
      fg: miiGrey,
      itemDisabled: miiWhite,
    },

    menu: {
      bg: miiWhite,
      item: miiGrey,
      itemDisabled: miiGreyLight,
    },
  },

  opacity: {
    flag: '0.7',
  },

  break: {
    horiz: {
      sm: '576px',
      lg: '768px',
    },
    vert: {
      sm: '480px',
      lg: '720px',
    },
  },
};

export const darkTheme = {
  color: {
    h1: miiWhite,
    h3: miiGrey,
    p: miiGrey,
    bgBody: miiGreyBrown,

    card: {
      bg: '#352e28',
      fg: miiGreyLight,
      bgHover: '#3a342f',
      heartBg: miiGreyLight,
      labelBg: miiGrey,
      LabelFg: miiGreyLight,
      bgIconPlay: '#554e48',
    },

    dropdown: {
      bg: miiGreyBrown,
      fg: miiGreyLight,
      itemDisabled: miiGreyMedium,
    },

    langsel: {
      bg: miiGreyLight,
      fg: miiGrey,
      itemDisabled: miiWhite,
    },

    menu: {
      bg: miiWhite,
      item: miiGrey,
      itemDisabled: miiGreyLight,
    },
  },

  opacity: {
    flag: '0.85',
  },

  break: {
    horiz: {
      sm: '576px',
      lg: '768px',
    },
    vert: {
      sm: '480px',
      lg: '720px',
    },
  },
};

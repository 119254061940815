import { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppStore, useSettingsStore } from '../../store';
import { getItemFromStorage } from '../../utils/localStorage';
import { isHttps } from '../../utils/system';
import { removeMarkdown, getPlainName } from '../../utils/string';
import Flags from 'country-flag-icons/react/3x2';
import RadioSvg from '../../images/radio.svg';
import RadioPng from '../../images/radio.png';
import {
  IoThumbsUpSharp,
  IoPlaySharp,
  IoLockClosedSharp,
} from 'react-icons/io5';
import { StationMenu, ToggleHeart } from '..';
import { sendStationClick } from '../../RadioBrowserApi';

import {
  Wrapper,
  Content,
  IconStation,
  NameStation,
  Footer,
  Label,
  ButtonWrap,
} from './ListRow.styles';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

export default function ListRow({ ...props }) {
  const [showImg, setShowImg] = useState(true);
  const [wiggleCard, setWiggleCard] = useState(false);

  const setActiveStation = useAppStore((state) => state.setActiveStation);
  const VIEW = useAppStore((state) => state.VIEW);
  const viewMode = useAppStore((state) => state.viewMode);
  const compactCard = useSettingsStore((state) => state.compactCard);
  const storeFavorite = useAppStore((state) => state.storeFavorite);
  const setStoreFavorite = useAppStore((state) => state.setStoreFavorite);

  if (!storeFavorite.move && wiggleCard) {
    setWiggleCard(false);
  }

  var stationMenuVisible = viewMode === VIEW.FAVORITES && !storeFavorite.move;

  const Flag = Flags[props.station.countrycode];

  const tags = props.station.tags
    .toString()
    .split(',')
    .filter((tag) => tag.length > 0 && tag.length < 16);

  const name = removeMarkdown(props.station.name);

  function startAudio(url) {
    props.player.current.audio.current.src = url;

    sendStationClick(props.station.stationuuid);

    setActiveStation({
      name: name,
      url: url,
      uuid: props.station.stationuuid,
      image: showImg ? props.station.favicon : RadioPng,
    });
  }

  function getEnabledItems() {
    var hasHomepage = 0;
    var hasMoreFavorites = 0;

    let array = getItemFromStorage('favorites');

    props.station.homepage ? (hasHomepage = 4) : (hasHomepage = 0);

    if (array) {
      if (array.results.length > 1) {
        hasMoreFavorites = 2;
      }
    }

    return hasHomepage + hasMoreFavorites;
  }

  return (
    <Wrapper
      $compactCard={compactCard}
      $move={storeFavorite.move}
      $wiggle={wiggleCard}
      onClick={() => {
        startAudio(props.station.url_resolved);
      }}
    >
      <Content>
        {showImg ? (
          <IconStation
            src={props.station.favicon}
            alt='station-image'
            onError={() => {
              setShowImg(false);
            }}
          />
        ) : (
          <IconStation src={RadioSvg} alt='station-image' />
        )}
        <NameStation $compactCard={compactCard}>
          {getPlainName(name)}
        </NameStation>
        <ButtonWrap>
          {stationMenuVisible && (
            <StationMenu
              id={props.id}
              showDots={true}
              setWiggleCard={setWiggleCard}
              enabledItems={getEnabledItems()}
              station={props.station}
            />
          )}
          {viewMode !== VIEW.FAVORITES && (
            <ToggleHeart
              checked={props.favorite}
              cbClicked={(checked) => {
                setStoreFavorite({
                  move: false,
                  station: props.station,
                  isFavorite: checked,
                });
              }}
            />
          )}
        </ButtonWrap>
        {!compactCard && (
          <Footer $compactCard={compactCard}>
            {viewMode !== VIEW.FAVORITES && (
              <>
                <Label color='cornflowerblue'>
                  <IoThumbsUpSharp />
                  {'\u00A0'}
                  {formatter.format(props.station.votes)}
                </Label>
                <Label color='#fa7d18'>
                  <IoPlaySharp />
                  {formatter.format(props.station.clickcount)}
                </Label>
              </>
            )}
            {Flag ? <Flag className='flag' /> : ''}
            {isHttps(props.station.url) && (
              <Label
                color='mediumseagreen'
                style={{
                  padding: '3px 4px',
                }}
              >
                <IoLockClosedSharp />
              </Label>
            )}
            {props.station.codec && (
              <Label color='darkorchid'>
                {props.station.codec + ' ' + props.station.bitrate}
              </Label>
            )}
            <Label>{tags[0]}</Label>
          </Footer>
        )}
      </Content>
    </Wrapper>
  );
}

ListRow.propTypes = {
  id: PropTypes.number,
  player: PropTypes.object,
  image: PropTypes.string,
  url: PropTypes.string,
  votes: PropTypes.number,
  countryCode: PropTypes.string,
  bitrate: PropTypes.number,
  name: PropTypes.string,
  tags: PropTypes.array,
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.$wiggle
      ? ({ theme }) => theme.color.card.bgHover
      : ({ theme }) => theme.color.card.bg};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-top: ${(props) => (props.$compactCard ? '50%' : '100%')};
  margin: 0 auto;
  border-radius: 8px;
  transition: 0.3s;
  user-select: none;
  animation-name: ${(props) => (props.$wiggle ? 'wiggle' : 'unset')};
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;

  @keyframes wiggle {
    0% {
      transform: rotate(-0.8deg);
      animation-timing-function: ease-in;
    }

    50% {
      transform: rotate(1.2deg);
      animation-timing-function: ease-out;
    }
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background-color: ${(props) =>
      props.$move
        ? ({ theme }) => theme.color.card.bg
        : ({ theme }) => theme.color.card.bgHover};
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  font-size: ${(props) => (props.$compactCard ? '0.75rem' : '0.85rem')};
  text-align: center;
  color: ${({ theme }) => theme.color.card.fg};

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    font-size: ${(props) => (props.$compactCard ? '0.55rem' : '0.65rem')};
  }
`;

export const IconPlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  height: 52px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0.9;
  background-color: ${({ theme }) => theme.color.card.bgIconPlay};
  transition: background-color 0.2s ease;
  padding: 10px 0 0 5px;
  color: white;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--miiOrange);
    opacity: 1;
  }
`;

export const NameStation = styled.div`
  color: ${(props) =>
    props.color ? 'white' : ({ theme }) => theme.color.card.labelFg};
  font-size: 0.85rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Label = styled.div`
  background: ${(props) =>
    props.color ? props.color : ({ theme }) => theme.color.card.labelBg};
  display: flex;
  pointer-events: none;
  align-items: center;
  padding: 3px 3px;
  font-size: 0.8rem;
  color: ${(props) =>
    props.color ? 'white' : ({ theme }) => theme.color.card.labelFg};

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    padding: 2px 2px;
    font-size: 0.65rem;
  }
`;

export const Footer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  width: 100%;
  max-height: 50px;
  overflow: hidden;

  .flag {
    opacity: ${({ theme }) => theme.opacity.flag};
    height: 1.3rem;
  }

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    max-height: 44px;

    .flag {
      height: 1.05rem;
    }
  }
`;

export const IconStation = styled.img`
  display: block;
  height: 52px;
  max-width: 70%;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    height: 42px;
  }
`;

export const MenuWrap = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: auto;
  user-select: none;
  z-index: 99;
`;

export const HeartWrap = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  width: auto;
`;

import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAppStore, useSettingsStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../';

import { Wrapper, Header, Content, Button } from './Grid.styles';

export default function Grid({ isLoading, children }) {
  const [lastBottom, setLastBottom] = useState(0);

  const VIEW = useAppStore((state) => state.VIEW);
  const viewMode = useAppStore((state) => state.viewMode);
  const forceRender = useAppStore((state) => state.forceRender);
  const settingsUnfold = useAppStore((state) => state.settingsUnfold);
  const toogleLoadMore = useAppStore((state) => state.toogleLoadMore);
  const modeList = useSettingsStore((state) => state.modeList);
  const msgDialog = useAppStore((state) => state.msgDialog);
  const showDialog = useAppStore((state) => state.showDialog);

  const { t } = useTranslation();

  const gridHeader = [
    { title: 'grid.empty-state' },
    { title: 'grid.empty-state' },
    { title: 'grid.empty-state' },
    { title: 'grid.favorites' },
    { title: 'grid.results' },
    { title: 'grid.uuid' },
  ];

  const node = useRef();
  const topRef = useRef();

  var modalActive = msgDialog.show;

  for (const item in showDialog) {
    modalActive |= showDialog[item];
  }

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'end',
    });
  }, [viewMode]);

  function onScroll() {
    if (node.current) {
      const { scrollTop, scrollHeight, clientHeight } = node.current;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        // 'lastBottom' prevents Safari from triggering 'toogleLoadMore' several times,
        // since Safari's 'scrollTop' value follows the elastic scroll bouncing
        if (lastBottom !== scrollHeight) {
          toogleLoadMore();
          setLastBottom(scrollHeight);
        }
      }
    }
  }

  return (
    <Wrapper
      onScroll={onScroll}
      ref={node}
      $unfold={settingsUnfold}
      style={{ filter: modalActive ? 'blur(4px)' : 'none' }}
    >
      <Header>
        <h1 ref={topRef}>{t(gridHeader[viewMode].title)}</h1>
        {viewMode === VIEW.UUID && (
          <Button
            onClick={() => {
              forceRender();
            }}
          />
        )}
      </Header>
      <Content $modeList={modeList}>{children}</Content>
      <Spinner $visible={isLoading} />
    </Wrapper>
  );
}

Grid.propTypes = {
  header: PropTypes.string,
  children: PropTypes.array,
};

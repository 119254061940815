import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { fetchCountries } from './RadioBrowserApi';

let appStore = (set) => ({
  system: {
    isTouch: false,
    isMobile: false,
    isSafari: false,
    isDevelop: false,
  },
  setSystem: (input) => set((state) => ({ system: input })),

  msgDialog: {
    show: false,
    title: '',
    content: null,
    footer: '',
  },
  setMsgDialog: (input) => set((state) => ({ msgDialog: input })),

  showDialog: {
    tips: false,
    info: false,
    deleteFavs: false,
  },
  setShowDialog: (input) => set((state) => ({ showDialog: input })),

  activeStation: {
    image: '',
    name: '',
    url: '',
    uuid: '',
  },
  setActiveStation: (input) => set((state) => ({ activeStation: input })),

  storeFavorite: {
    move: false,
    station: null,
    isFavorite: undefined,
  },
  setStoreFavorite: (input) => set((state) => ({ storeFavorite: input })),

  error: {
    set: false,
    title: '',
    msg: '',
    action: '',
  },
  setError: (input) => set((state) => ({ error: input })),

  VIEW: {
    EMPTY_STATE_FAVS: 0,
    EMPTY_STATE_RESULTS: 1,
    EMPTY_STATE_ERROR: 2,
    FAVORITES: 3,
    RESULTS: 4,
    UUID: 5,
  },

  viewMode: 0,
  setViewMode: (input) => set((state) => ({ viewMode: input })),

  searchOptions: {
    searchMode: 'name',
    country: '',
    order: 'clickcount',
    orderIndex: 1,
    bitrateMin: 0,
    bitrateMax: '',
    reverse: true,
    reverseIndex: 1,
  },
  setSearchOptions: (input) => set((state) => ({ searchOptions: input })),

  countries: {},
  setCountries: async () => {
    try {
      const result = await fetchCountries();
      set((state) => ({ countries: result }));
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  },

  loadMore: false,
  toogleLoadMore: () => set((state) => ({ loadMore: !state.loadMore })),

  searchTerm: 0,
  setSearchTerm: (input) => set((state) => ({ searchTerm: input })),

  settingsUnfold: false,
  toggleShowSettings: () =>
    set((state) => ({ settingsUnfold: !state.settingsUnfold })),

  defKeysEnabled: false,
  setDefKeysEnabled: (input) => set((state) => ({ defKeysEnabled: input })),

  stationId: 0,
  setStationId: (input) => set((state) => ({ stationId: input })),

  render: false,
  forceRender: () => set((state) => ({ render: !state.render })),

  showSongInfo: false,
  setShowSongInfo: (input) => set((state) => ({ showSongInfo: input })),

  fetchMetadata: false,
  setFetchMetadata: (input) => set((state) => ({ fetchMetadata: input })),

  showInfoSpinner: false,
  setShowInfoSpinner: (input) => set((state) => ({ showInfoSpinner: input })),
});

let settingsStore = (set) => ({
  initialStart: true,
  setInitialStart: (input) => set((state) => ({ initialStart: input })),

  hqBitrate: 128,
  setHqBitrate: (input) => set((state) => ({ hqBitrate: input })),

  hideBroken: false,
  setHideBroken: (input) => set((state) => ({ hideBroken: input })),

  showTooltips: true,
  setShowTooltips: (input) => set((state) => ({ showTooltips: input })),

  modeList: false,
  setModeList: (input) => set((state) => ({ modeList: input })),

  light: true,
  toggleLight: () => set((state) => ({ light: !state.light })),

  compactCard: false,
  toggleCompact: () => set((state) => ({ compactCard: !state.compactCard })),
});

settingsStore = persist(settingsStore, { name: 'mii_settings' });

export const useAppStore = create(appStore);
export const useSettingsStore = create(settingsStore);

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) =>
    ({ theme }) =>
      theme.color.card.bg};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 50px;

  transition: 0.3s;
  user-select: none;
  animation-name: ${(props) => (props.$wiggle ? 'wiggle' : 'unset')};
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;

  @keyframes wiggle {
    0% {
      transform: rotate(-0.8deg);
      animation-timing-function: ease-in;
    }

    50% {
      transform: rotate(1.2deg);
      animation-timing-function: ease-out;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 60px auto 40px;
  grid-template-rows: 50% 50%;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.color.card.fg};
`;

export const NameStation = styled.div`
  // 'grid-row-start', 'grid-column-start', 'grid-row-end', and 'grid-column-end'
  grid-area: ${(props) =>
    props.$compactCard ? '1 / 2 / 3 / 3' : '1 / 2 / 2 / 3'};
  color: ${(props) =>
    props.color ? 'white' : ({ theme }) => theme.color.card.labelFg};
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
`;

export const Label = styled.div`
  background: ${(props) =>
    props.color ? props.color : ({ theme }) => theme.color.card.labelBg};
  color: ${(props) =>
    props.color ? 'white' : ({ theme }) => theme.color.card.labelFg};
  display: flex;
  pointer-events: none;
  align-items: center;
  padding: 2px 3px;
  font-size: 0.7rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Footer = styled.div`
  grid-column: 2;
  grid-row: 2;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  overflow: hidden;
  gap: 4px;
  height: 100%;
  padding-top: 2px;

  .flag {
    opacity: ${({ theme }) => theme.opacity.flag};
    height: 1.1rem;
  }
`;

export const IconStation = styled.img`
  grid-column: 1;
  grid-row: 1 / 3;
  width: 50px;
  max-height: 50px;
`;

export const ButtonWrap = styled.div`
  grid-column: 3;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-right: 6px;
`;

import { useState, useRef } from 'react';
import { useAppStore, useSettingsStore } from '../store';
import { useHotkeys } from '../hooks/useHotkeys';
import { useFetchStations } from '../hooks/useFetchStations';
import { useStoreFavorites } from '../hooks/useStoreFavorites';
import { getItemFromStorage } from '../utils/localStorage';
import { Card, ListRow, Grid } from '.';

import { EmptyState, Header, SearchBar, Footer } from '.';

const Main = ({ ...props }) => {
  let favorites = getItemFromStorage('favorites');

  const { stations, isLoading } = useFetchStations();
  const [virtualKey, setVirtualKey] = useState(false);
  const modeList = useSettingsStore((state) => state.modeList);
  const player = useRef(null);
  const cardItemsRef = useRef(null);

  const { VIEW, viewMode, system, storeFavorite } = useAppStore((state) => ({
    VIEW: state.VIEW,
    viewMode: state.viewMode,
    system: state.system,
    storeFavorite: state.storeFavorite,
  }));

  const { setKey } = useStoreFavorites(storeFavorite);

  const getCardRefsMap = () => {
    if (!cardItemsRef.current) {
      cardItemsRef.current = new Map();
    }

    return cardItemsRef.current;
  };

  const scrollToCard = (itemId) => {
    const map = getCardRefsMap();
    const node = map.get(itemId);

    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useHotkeys(setKey, virtualKey, setVirtualKey, player, scrollToCard);

  const showEmpty =
    viewMode === VIEW.EMPTY_STATE_FAVS ||
    viewMode === VIEW.EMPTY_STATE_RESULTS ||
    viewMode === VIEW.EMPTY_STATE_ERROR;

  return (
    <>
      <Header player={player} {...props} />
      <SearchBar />
      {showEmpty ? (
        <EmptyState />
      ) : stations ? (
        <Grid isLoading={isLoading}>
          {stations.results.map((entry, idx) => {
            return (
              <div
                key={entry.stationuuid}
                ref={(node) => {
                  const map = getCardRefsMap();
                  node
                    ? map.set(entry.stationuuid, node)
                    : map.delete(entry.stationuuid);
                }}
              >
                {modeList ? (
                  <ListRow
                    id={idx}
                    player={player}
                    station={entry}
                    favorite={
                      favorites
                        ? favorites.results.some(
                            (e) => e.stationuuid === entry.stationuuid
                          )
                        : false
                    }
                  />
                ) : (
                  <Card
                    id={idx}
                    player={player}
                    station={entry}
                    favorite={
                      favorites
                        ? favorites.results.some(
                            (e) => e.stationuuid === entry.stationuuid
                          )
                        : false
                    }
                  />
                )}
              </div>
            );
          })}
        </Grid>
      ) : null}
      <Footer
        show={storeFavorite.move && system.isTouch}
        setVirtualKey={setVirtualKey}
      />
    </>
  );
};

export default Main;

import { useState, useEffect } from 'react';
import { useAppStore, useSettingsStore } from '../store';
import { clearStorage } from '../utils/localStorage';
import { isDevelop } from '../utils/system';
import {
  searchStations,
  getStationById,
  getStationsMostVoted,
  getStationsMostClicked,
  getStationsRecentlyClicked,
  controller,
} from '../RadioBrowserApi';

import { getItemFromStorage } from '../utils/localStorage';

const initialState = {
  results: [],
};

export function useFetchStations() {
  const [stations, setStations] = useState(initialState);
  const [page, setPage] = useState(0);
  const [moreAvailable, setMoreAvailable] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const loadMore = useAppStore((state) => state.loadMore);
  const searchTerm = useAppStore((state) => state.searchTerm);
  const render = useAppStore((state) => state.render);
  const stationId = useAppStore((state) => state.stationId);
  const setStationId = useAppStore((state) => state.setStationId);
  const setError = useAppStore((state) => state.setError);
  const VIEW = useAppStore((state) => state.VIEW);
  const setViewMode = useAppStore((state) => state.setViewMode);
  const searchOptions = useAppStore((state) => state.searchOptions);

  const compactCard = useSettingsStore((state) => state.compactCard);
  const hideBroken = useSettingsStore((state) => state.hideBroken);

  const maxStationsPage = compactCard ? 50 : 30;

  async function getStations(page, searchTerm = '') {
    let countryCode = null;
    let stations = null;
    let fetchMode = 0;

    if (controller) controller.abort();

    try {
      setisLoading(true);

      if (stationId) {
        stations = await getStationById([stationId]);
      } else {
        // parse search entry for commands like '/de'
        const searchCommand = searchTerm.charAt(0) === '/';

        if (searchCommand) {
          if (searchTerm.length === 3) {
            countryCode = searchTerm.substr(1, 2);
            fetchMode = 2;
          } else if (searchTerm === '/votes') {
            fetchMode = 4;
          } else if (searchTerm === '/clicks') {
            fetchMode = 8;
          } else if (searchTerm === '/recent') {
            fetchMode = 16;
          } else if (isDevelop && searchTerm === '/cls') {
            clearStorage();
            setisLoading(false);
            return;
          }
        }

        const options = {
          country: searchOptions.country,
          bitrateMin: searchOptions.bitrateMin,
          bitrateMax: searchOptions.bitrateMax,
          order: searchOptions.order,
          reverse: searchOptions.reverse,
          hidebroken: hideBroken,
          limit: maxStationsPage,
          offset: page,
        };

        // avoid 'fallthrough' warning
        /* eslint-disable */
        switch (fetchMode) {
          case 16:
            stations = await getStationsRecentlyClicked(options);
            break;
          case 8:
            stations = await getStationsMostClicked(options);
            break;
          case 4:
            stations = await getStationsMostVoted(options);
            break;
          case 2:
            countryCode = searchTerm.substr(1, 2);
          case 0:
            const search = {
              name: searchOptions.searchMode === 'name' ? searchTerm : '',
              tag: searchOptions.searchMode === 'genre' ? searchTerm : '',
            };

            const searchCountry = {
              countrycode: countryCode,
            };

            const query = countryCode
              ? { ...searchCountry, ...options }
              : { ...search, ...options };

            stations = await searchStations(query);
        }
        /* eslint-enable */
      }

      if (stations.length < maxStationsPage - 1) {
        setMoreAvailable(false);
      } else {
        setPage(page + maxStationsPage);
        setMoreAvailable(true);
      }

      setStations((prev) => ({
        results: page > 1 ? [...prev.results, ...stations] : [...stations],
      }));
    } catch (error) {
      setError({
        set: true,
        title: 'Fetch stations',
        msg: 'An error occured - please refresh the browser',
      });
    }

    if (!stations) {
      setisLoading(false);
      return;
    }

    if (stationId && stations.length) {
      setViewMode(VIEW.UUID);
    } else if (stations.length) {
      setViewMode(VIEW.RESULTS);
    } else {
      setViewMode(VIEW.EMPTY_STATE_RESULTS);
    }

    setisLoading(false);
  }

  // Search and initial
  useEffect(() => {
    if (!searchTerm && !stationId) {
      const favoritesState = getItemFromStorage('favorites');

      favoritesState ? setStations(favoritesState) : setStations(initialState);

      if (favoritesState !== null) {
        favoritesState.results.length
          ? setViewMode(VIEW.FAVORITES)
          : setViewMode(VIEW.EMPTY_STATE_FAVS);
      }

      setMoreAvailable(false);

      return;
    }

    setStationId(0);
    setStations(initialState);
    getStations(0, searchTerm);
  }, [searchTerm, searchOptions, render]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load More
  useEffect(() => {
    if (!moreAvailable || isLoading) return;
    getStations(page, searchTerm);
  }, [loadMore]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    stations,
    isLoading,
  };
}
